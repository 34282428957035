.sw-guidanceMessage .sw-guidanceMsg-textContainer .sw-guidanceMsg-text {
    display: flex !important;
  }  

.aw-layout-panelMain .aw-layout-panelSection {
    margin-bottom: 24px;
    margin-top: 0;
  }
  
  .aw-widgets-cellListCellCommands .aw-widgets-cellCommandsContainer .aw-cell-command-bar .aw-command .aw-commands-commandIconButton .aw-commands-svg {
    width: 24px !important;
    height: 24px !important;
  }
  
  .aw-layout-primaryWorkarea.aw-layout-flexRowContainer.afx-alt-content-background.aw-layout-primaryWorkareaLeft.aw-layout-primaryWorkareaFill {
    padding: 16px !important;
    background: #fff !important;
  }
  .aw-layout-primaryWorkarea.aw-layout-flexRowContainer.afx-alt-content-background.aw-layout-primaryWorkareaLeft.aw-layout-primaryWorkareaFill.opin-quick-composer-dashboard {
    padding-top: 5px !important;
    padding-left: 5px !important;
    padding-bottom: 5px !important;
    background: #fff !important;
  }
  summary.sw-column.sw-panel-summary.aw-layout-collapsiblePanelSectionTitle.collapsible {
    background-color: #f0f0f0 !important;
  }
  summary.sw-column.sw-panel-summary.notCollapsible {
    background-color: #f0f0f0 !important;
  }
  
  .mom-layout-main-view .aw-command-bar.aw-commands-commandBarVertical .aw-commands-commandIconButton {
    padding: 6px 0 !important;
  }
  
  .aw-advanced-bar.aw-advanced-bar-horizontal.afx-alt-content-background[type=TOOLBAR] {
    border-top: 0px !important;
  }
  
  div.sw-section-content > label.sw-property.sw-component.ocin-label > span.sw-property-name {
    max-width: 200px !important;
  }
  
  .mom-empty-content .mom-empty-icon-container {
    margin-top: 50px !important;
  }
  /* HEIGHT 100% is to manage correctly the InsightHub black command bar. Withous this override the page is vertically cut */
  .aw-layout-mainView {
    width: 100% !important;
    height: 100% !important;
  }

  .aw-layout-workareaCommandbar.aw-theme-toolbar {
    background: rgb(255, 255, 255);
    border-top: 1px solid rgb(220, 220, 220);
  }

  .mom-details-advanced-toolbar {
    min-height: 39px;
    border-color: black;
    box-shadow: #0000004d 0 2px;
    margin-bottom: 5px;
    width: 99.9%;
  }
  